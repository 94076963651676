import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ProductsCategory } from '../../../config';
import { getProducts } from '../../../commons/utils/productsHelper';
import { productsKeyConstants } from '../../../config';
import {
  GAPageViewItem,
  GASelectedItem,
} from '../../../commons/helpers/Analytics/googleAnalytics';
import {
  FBAddToCart,
  FBViewContent,
} from '../../../commons/helpers/Analytics/fbPixel';
import { ipAddressInfo } from '../../../src/features/user/userState';
import { disableBrokenCart } from '../../../src/features/ui/uiState';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../src/app/hooks';
import { setReferralCode } from '../../../src/features/cart/cartState';
import dynamic from 'next/dynamic';
import ProductShowcase from '../../mui5/homepage/ProductShowCase/ProductShowcase';
import AsSeenIn from '../../mui5/homepage/AsSeenIn/AsSeenIn';
import CustomerStories from '../../mui5/homepage/customerStories/customerStories';
import WhyReliefFactor from '../../mui5/homepage/whyReliefFactor/whyReliefFactor';

import { PinterestPageVisit } from '../../../commons/helpers/Analytics/pinterest';

// const Pitch = dynamic(() => import('./pitch/Pitch'));
const BannerCarousal = dynamic(() =>
  import('../../mui5/homepage/bannerCarousal')
);
// const BuyingOptions = dynamic(() => import('./buyingOptions/BuyingOptions'));
// const Difference = dynamic(() => import('./difference/Difference'));
// const Solution = dynamic(() => import('./solution/Solution'));
// const Ingredients = dynamic(() => import('./ingredients/Ingredients'));
// const Testimonials = dynamic(() => import('./testimonials/Testimonials'));
// const WhyReliefFactor = dynamic(() =>
//   import('../../mui5/homepage/whyReliefFactor/whyReliefFactor')
// );
// const CustomerStories = dynamic(
//   () => import('../../mui5/homepage/customerStories/customerStories'),
//   {
//     ssr: false,
//   }
// );
export default function HomePage() {
  const router = useRouter();
  // const { query } = useRouter();
  const dispatch = useAppDispatch();
  const [reviewsRef, setReviewsRef] = useState(null);

  useEffect(() => {
    if (router?.query?.couponcode) {
      dispatch(setReferralCode(router?.query.couponcode));
      router.push('/');
    }
  }, [router?.query]);

  useEffect(() => {
    dispatch(disableBrokenCart(false));
  }, []);

  const products = getProducts();
  const ipaddress = useAppSelector(ipAddressInfo);
  const quickStartTag = productsKeyConstants.RF_RELIEF_FACTOR_60_CT;
  const rfsingleTag = productsKeyConstants.RF_RELIEF_FACTOR_60_CT_SINGLE;
  const goSubscription = productsKeyConstants.RF_GO_FACTOR;
  const goSingle = productsKeyConstants.RF_GO_FACTOR_SINGLE;
  const zenSubscription = productsKeyConstants.RF_ZEN_FACTOR;
  const zenSingle = productsKeyConstants.RF_ZEN_FACTOR_SINGLE;

  const zSubscription = productsKeyConstants.RF_Z_FACTOR;
  const zSingle = productsKeyConstants.RF_Z_FACTOR_SINGLE;

  useEffect(() => {
    if (products) {
      GAPageViewItem(
        [
          quickStartTag,
          rfsingleTag,
          goSubscription,
          goSingle,
          zenSubscription,
          zenSingle,
          zSubscription,
          zSingle,
        ],
        products
      );
      PinterestPageVisit(
        [
          quickStartTag,
          rfsingleTag,
          goSubscription,
          goSingle,
          zenSubscription,
          zenSingle,
          zSubscription,
          zSingle,
        ],
        products
      );
    }
  }, [products]);

  useEffect(() => {
    if (ipaddress !== null) {
      FBViewContent(
        [
          quickStartTag,
          rfsingleTag,
          goSubscription,
          goSingle,
          zenSubscription,
          zenSingle,
        ],
        products
      );
      FBViewContent([zSubscription, zSingle], products, true);
    }
  }, [ipaddress]);

  const handleOnScroll = (key, offset, ref = null) => {
    if (key == 1) {
      if (window) {
        if (window.scrollTo)
          window.scrollTo(0, reviewsRef.current.offsetTop - offset);
      }
    } else {
      if (window) {
        if (window.scrollTo) window.scrollTo(0, ref.current.offsetTop - offset);
      }
    }
  };

  return (
    <>
      <BannerCarousal />
      <ProductShowcase />
      <WhyReliefFactor />
      <CustomerStories />
      <AsSeenIn />
    </>
  );
}
